import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../Components/Layout';
import Article from '../Components/Article/Article';
import SEO from '../Components/SEO';

const ArticlePage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
    const { articles } = frontmatter;

    return (
        <Layout>
            <SEO title="SpotOn articles" description="SpotOn articles" />
            <div className="mt-20"></div>
            {articles.map((article) => (
                <Article article={article} />
            ))}
        </Layout>
    );
};

export default ArticlePage;

export const pageQuery = graphql`
    query ArticlesPage {
        markdownRemark(frontmatter: { templateKey: { eq: "articles-page" } }) {
            frontmatter {
                articles {
                    title
                    short
                    slug
                    content
                    images {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: WEBP
                                layout: FULL_WIDTH
                            )
                        }
                    }
                }
            }
        }
    }
`;
